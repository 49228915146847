import { createCreative } from './creative';
export function createCreativeLinear() {
  var creativeAttributes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _createCreative = createCreative(creativeAttributes),
    id = _createCreative.id,
    adId = _createCreative.adId,
    sequence = _createCreative.sequence,
    apiFramework = _createCreative.apiFramework;
  return {
    id: id,
    adId: adId,
    sequence: sequence,
    apiFramework: apiFramework,
    type: 'linear',
    duration: 0,
    skipDelay: null,
    mediaFiles: [],
    mezzanine: null,
    interactiveCreativeFile: null,
    closedCaptionFiles: [],
    videoClickThroughURLTemplate: null,
    videoClickTrackingURLTemplates: [],
    videoCustomClickURLTemplates: [],
    adParameters: null,
    icons: [],
    trackingEvents: {}
  };
}
export function isCreativeLinear(ad) {
  return ad.type === 'linear';
}