import { createPalette, createThemeConfig } from '../lib';
var darks = {
  50: '#e3e7ec',
  100: '#b8c4cf',
  200: '#899daf',
  300: '#59768e',
  400: '#365876',
  500: '#123b5e',
  600: '#103556',
  700: '#0d2d4c',
  800: '#0a2642',
  900: '#051931'
};
var highlights = {
  50: '#e0eefa',
  100: '#b3d5f2',
  200: '#80b9e9',
  300: '#4d9ce0',
  400: '#2687d9',
  500: '#0072d2',
  600: '#006acd',
  700: '#005fc7',
  800: '#0055c1',
  900: '#0042b6'
};
export var blueTheme = createThemeConfig({
  name: 'The Blue Ocean',
  palette: createPalette({
    darks: darks,
    highlights: highlights
  }, {
    text: '#F9F9F9',
    buttonText: highlights[50]
  }),
  logo_size: 400,
  logo: 'images/logos/logo@0.75x.png',
  fonts: {
    name: 'Montserrat',
    Text: 'fonts/Montserrat/Montserrat-Light.ttf',
    Regular: 'fonts/Montserrat/Montserrat-Regular.ttf',
    Bold: 'fonts/Montserrat/Montserrat-SemiBold.ttf',
    ExtraBold: 'fonts/Montserrat/Montserrat-Bold.ttf'
  },
  components: {
    VideoCardConfig: {
      radius: 4,
      textHighlightColor: highlights[50]
    },
    MainMenuConfig: {
      radius: 4
    },
    SimpleCardConfig: {
      radius: 4,
      imageRadius: 4
    },
    BoxCardConfig: {
      radius: 4,
      textHighlightColor: highlights[50]
    }
  }
});