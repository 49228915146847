function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { util } from '../util/util';

/**
 * This module provides support methods to the parsing classes.
 */

/**
 * Returns the first element of the given node which nodeName matches the given name.
 * @param  {Node} node - The node to use to find a match.
 * @param  {String} name - The name to look for.
 * @return {Object|undefined}
 */
function childByName(node, name) {
  var childNodes = node.childNodes;
  for (var childKey in childNodes) {
    var child = childNodes[childKey];
    if (child.nodeName === name) {
      return child;
    }
  }
}

/**
 * Returns all the elements of the given node which nodeName match the given name.
 * @param  {Node} node - The node to use to find the matches.
 * @param  {String} name - The name to look for.
 * @return {Array}
 */
function childrenByName(node, name) {
  var children = [];
  var childNodes = node.childNodes;
  for (var childKey in childNodes) {
    var child = childNodes[childKey];
    if (child.nodeName === name) {
      children.push(child);
    }
  }
  return children;
}

/**
 * Converts relative vastAdTagUri.
 * @param  {String} vastAdTagUrl - The url to resolve.
 * @param  {String} originalUrl - The original url.
 * @return {String}
 */
function resolveVastAdTagURI(vastAdTagUrl, originalUrl) {
  if (!originalUrl) {
    return vastAdTagUrl;
  }
  if (vastAdTagUrl.indexOf('//') === 0) {
    var _location = location,
      protocol = _location.protocol;
    return "".concat(protocol).concat(vastAdTagUrl);
  }
  if (vastAdTagUrl.indexOf('://') === -1) {
    // Resolve relative URLs (mainly for unit testing)
    var baseURL = originalUrl.slice(0, originalUrl.lastIndexOf('/'));
    return "".concat(baseURL, "/").concat(vastAdTagUrl);
  }
  return vastAdTagUrl;
}

/**
 * Converts a boolean string into a Boolean.
 * @param  {String} booleanString - The boolean string to convert.
 * @return {Boolean}
 */
function parseBoolean(booleanString) {
  return ['true', 'TRUE', 'True', '1'].indexOf(booleanString) !== -1;
}

/**
 * Parses a node text (for legacy support).
 * @param  {Object} node - The node to parse the text from.
 * @return {String}
 */
function parseNodeText(node) {
  return node && (node.textContent || node.text || '').trim();
}

/**
 * Copies an attribute from a node to another.
 * @param  {String} attributeName - The name of the attribute to clone.
 * @param  {Object} nodeSource - The source node to copy the attribute from.
 * @param  {Object} nodeDestination - The destination node to copy the attribute at.
 */
function copyNodeAttribute(attributeName, nodeSource, nodeDestination) {
  var attributeValue = nodeSource.getAttribute(attributeName);
  if (attributeValue) {
    nodeDestination.setAttribute(attributeName, attributeValue);
  }
}

/**
 * Converts element attributes into an object, where object key is attribute name
 * and object value is attribute value
 * @param {Element} element
 * @returns {Object}
 */
function parseAttributes(element) {
  var nodeAttributes = element.attributes;
  var attributes = {};
  for (var i = 0; i < nodeAttributes.length; i++) {
    attributes[nodeAttributes[i].nodeName] = nodeAttributes[i].nodeValue;
  }
  return attributes;
}

/**
 * Parses a String duration into a Number.
 * @param  {String} durationString - The dureation represented as a string.
 * @return {Number}
 */
function parseDuration(durationString) {
  if (durationString === null || typeof durationString === 'undefined') {
    return -1;
  }
  // Some VAST doesn't have an HH:MM:SS duration format but instead jus the number of seconds
  if (util.isNumeric(durationString)) {
    return parseInt(durationString);
  }
  var durationComponents = durationString.split(':');
  if (durationComponents.length !== 3) {
    return -1;
  }
  var secondsAndMS = durationComponents[2].split('.');
  var seconds = parseInt(secondsAndMS[0]);
  if (secondsAndMS.length === 2) {
    seconds += parseFloat("0.".concat(secondsAndMS[1]));
  }
  var minutes = parseInt(durationComponents[1] * 60);
  var hours = parseInt(durationComponents[0] * 60 * 60);
  if (isNaN(hours) || isNaN(minutes) || isNaN(seconds) || minutes > 60 * 60 || seconds > 60) {
    return -1;
  }
  return hours + minutes + seconds;
}

/**
 * Splits an Array of ads into an Array of Arrays of ads.
 * Each subarray contains either one ad or multiple ads (an AdPod)
 * @param  {Array} ads - An Array of ads to split
 * @return {Array}
 */
function splitVAST(ads) {
  var splittedVAST = [];
  var lastAdPod = null;
  ads.forEach(function (ad, i) {
    if (ad.sequence) {
      ad.sequence = parseInt(ad.sequence, 10);
    }
    // The current Ad may be the next Ad of an AdPod
    if (ad.sequence > 1) {
      var lastAd = ads[i - 1];
      // check if the current Ad is exactly the next one in the AdPod
      if (lastAd && lastAd.sequence === ad.sequence - 1) {
        lastAdPod && lastAdPod.push(ad);
        return;
      }
      // If the ad had a sequence attribute but it was not part of a correctly formed
      // AdPod, let's remove the sequence attribute
      delete ad.sequence;
    }
    lastAdPod = [ad];
    splittedVAST.push(lastAdPod);
  });
  return splittedVAST;
}

/**
 * Parses the attributes and assign them to object
 * @param  {Object} attributes attribute
 * @param  {Object} verificationObject with properties which can be assigned
 */
function assignAttributes(attributes, verificationObject) {
  if (attributes) {
    for (var attrKey in attributes) {
      var attribute = attributes[attrKey];
      if (attribute.nodeName && attribute.nodeValue && verificationObject.hasOwnProperty(attribute.nodeName)) {
        var value = attribute.nodeValue;
        if (typeof verificationObject[attribute.nodeName] === 'boolean') {
          value = parseBoolean(value);
        }
        verificationObject[attribute.nodeName] = value;
      }
    }
  }
}

/**
 * Merges the data between an unwrapped ad and his wrapper.
 * @param  {Ad} unwrappedAd - The 'unwrapped' Ad.
 * @param  {Ad} wrapper - The wrapper Ad.
 * @return {void}
 */
function mergeWrapperAdData(unwrappedAd, wrapper) {
  unwrappedAd.errorURLTemplates = wrapper.errorURLTemplates.concat(unwrappedAd.errorURLTemplates);
  unwrappedAd.impressionURLTemplates = wrapper.impressionURLTemplates.concat(unwrappedAd.impressionURLTemplates);
  unwrappedAd.extensions = wrapper.extensions.concat(unwrappedAd.extensions);
  if (wrapper.viewableImpression.length > 0) {
    unwrappedAd.viewableImpression = [].concat(_toConsumableArray(unwrappedAd.viewableImpression), _toConsumableArray(wrapper.viewableImpression));
  }

  // values from the child wrapper will be overridden
  unwrappedAd.followAdditionalWrappers = wrapper.followAdditionalWrappers;
  unwrappedAd.allowMultipleAds = wrapper.allowMultipleAds;
  unwrappedAd.fallbackOnNoAd = wrapper.fallbackOnNoAd;
  var wrapperCompanions = (wrapper.creatives || []).filter(function (creative) {
    return creative && creative.type === 'companion';
  });
  var wrapperCompanionClickTracking = wrapperCompanions.reduce(function (result, creative) {
    (creative.variations || []).forEach(function (variation) {
      (variation.companionClickTrackingURLTemplates || []).forEach(function (companionClickTrackingURLTemplate) {
        if (!util.containsTemplateObject(companionClickTrackingURLTemplate, result)) {
          result.push(companionClickTrackingURLTemplate);
        }
      });
    });
    return result;
  }, []);
  unwrappedAd.creatives = wrapperCompanions.concat(unwrappedAd.creatives);
  var wrapperHasVideoClickTracking = wrapper.videoClickTrackingURLTemplates && wrapper.videoClickTrackingURLTemplates.length;
  var wrapperHasVideoCustomClick = wrapper.videoCustomClickURLTemplates && wrapper.videoCustomClickURLTemplates.length;
  unwrappedAd.creatives.forEach(function (creative) {
    // merge tracking events
    if (wrapper.trackingEvents && wrapper.trackingEvents[creative.type]) {
      for (var eventName in wrapper.trackingEvents[creative.type]) {
        var urls = wrapper.trackingEvents[creative.type][eventName];
        if (!Array.isArray(creative.trackingEvents[eventName])) {
          creative.trackingEvents[eventName] = [];
        }
        creative.trackingEvents[eventName] = creative.trackingEvents[eventName].concat(urls);
      }
    }
    if (creative.type === 'linear') {
      // merge video click tracking url
      if (wrapperHasVideoClickTracking) {
        creative.videoClickTrackingURLTemplates = creative.videoClickTrackingURLTemplates.concat(wrapper.videoClickTrackingURLTemplates);
      }

      // merge video custom click url
      if (wrapperHasVideoCustomClick) {
        creative.videoCustomClickURLTemplates = creative.videoCustomClickURLTemplates.concat(wrapper.videoCustomClickURLTemplates);
      }

      // VAST 2.0 support - Use Wrapper/linear/clickThrough when Inline/Linear/clickThrough is null
      if (wrapper.videoClickThroughURLTemplate && (creative.videoClickThroughURLTemplate === null || typeof creative.videoClickThroughURLTemplate === 'undefined')) {
        creative.videoClickThroughURLTemplate = wrapper.videoClickThroughURLTemplate;
      }
    }

    // pass wrapper companion trackers to all companions
    if (creative.type === 'companion' && wrapperCompanionClickTracking.length) {
      (creative.variations || []).forEach(function (variation) {
        variation.companionClickTrackingURLTemplates = util.joinArrayOfUniqueTemplateObjs(variation.companionClickTrackingURLTemplates, wrapperCompanionClickTracking);
      });
    }
  });
  if (wrapper.adVerifications) {
    // As specified by VAST specs unwrapped ads should contains wrapper adVerification script
    unwrappedAd.adVerifications = unwrappedAd.adVerifications.concat(wrapper.adVerifications);
  }
  if (wrapper.blockedAdCategories) {
    unwrappedAd.blockedAdCategories = unwrappedAd.blockedAdCategories.concat(wrapper.blockedAdCategories);
  }
}
export var parserUtils = {
  childByName: childByName,
  childrenByName: childrenByName,
  resolveVastAdTagURI: resolveVastAdTagURI,
  parseBoolean: parseBoolean,
  parseNodeText: parseNodeText,
  copyNodeAttribute: copyNodeAttribute,
  parseAttributes: parseAttributes,
  parseDuration: parseDuration,
  splitVAST: splitVAST,
  assignAttributes: assignAttributes,
  mergeWrapperAdData: mergeWrapperAdData
};