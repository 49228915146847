import { createCreative } from './creative';
export function createCreativeNonLinear() {
  var creativeAttributes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _createCreative = createCreative(creativeAttributes),
    id = _createCreative.id,
    adId = _createCreative.adId,
    sequence = _createCreative.sequence,
    apiFramework = _createCreative.apiFramework;
  return {
    id: id,
    adId: adId,
    sequence: sequence,
    apiFramework: apiFramework,
    type: 'nonlinear',
    variations: [],
    trackingEvents: {}
  };
}