import { MainMenu } from '../MainMenu';
export var defaultDetailCoordinates = {
  visible: false,
  width: 1920,
  contentWidth: 1200,
  contentHeight: 400,
  height: 800,
  left: MainMenu.widthClosed + 160,
  top: 32,
  smooth: false,
  hasButtons: false,
  maxDescriptionLines: 4
};