import FW from '../framework/fw';
import ENV from '../framework/env';
import Utils from '../framework/utils';
import TRACKING_EVENTS from '../tracking/tracking-events';
import VAST_PLAYER from '../players/vast-player';
import RmpConnection from '../assets/rmp-connection/rmp-connection';
var LINEAR = {};
var HTML5_MEDIA_ERROR_TYPES = ['MEDIA_ERR_CUSTOM', 'MEDIA_ERR_ABORTED', 'MEDIA_ERR_NETWORK', 'MEDIA_ERR_DECODE', 'MEDIA_ERR_SRC_NOT_SUPPORTED', 'MEDIA_ERR_ENCRYPTED'];
var COMMON_VIDEO_FORMATS = ['video/webm', 'video/mp4', 'video/ogg', 'video/3gpp'];
var _onDurationChange = function _onDurationChange() {
  var _this = this;
  this.vastPlayerDuration = VAST_PLAYER.getDuration.call(this);
  Utils.createApiEvent.call(this, 'addurationchange');
  // progress event
  if (this.vastPlayerDuration === -1) {
    return;
  }
  var keys = Object.keys(this.creative.trackingEvents);
  keys.forEach(function (eventName) {
    if (/progress-/i.test(eventName)) {
      var time = eventName.split('-');
      var time_2 = time[1];
      if (/%/i.test(time_2)) {
        var timePerCent = time_2.slice(0, -1);
        timePerCent = _this.vastPlayerDuration * parseFloat(timePerCent) / 100;
        var trackingUrls = _this.creative.trackingEvents[eventName];
        trackingUrls.forEach(function (url) {
          _this.progressEvents.push({
            time: timePerCent,
            url: url
          });
        });
      } else {
        var _trackingUrls = _this.creative.trackingEvents[eventName];
        _trackingUrls.forEach(function (url) {
          _this.progressEvents.push({
            time: parseFloat(time_2) * 1000,
            url: url
          });
        });
      }
    }
  });
  // sort progress time ascending
  if (this.progressEvents.length > 0) {
    this.progressEvents.sort(function (a, b) {
      return a.time - b.time;
    });
  }
};
var _onLoadedmetadataPlay = function _onLoadedmetadataPlay() {
  clearTimeout(this.creativeLoadTimeoutCallback);
  // adjust volume to make sure content player volume matches vast player volume
  if (this.vastPlayer) {
    if (this.vastPlayer.volume !== this.contentPlayer.volume) {
      this.vastPlayer.volume = this.contentPlayer.volume;
    }
    if (this.contentPlayer.muted) {
      this.vastPlayer.muted = true;
    } else {
      this.vastPlayer.muted = false;
    }
  }
  // show ad container holding vast player
  FW.show(this.adContainer);
  FW.show(this.vastPlayer);
  this.adOnStage = true;
  // play VAST player
  VAST_PLAYER.play.call(this, this.firstVastPlayerPlayRequest);
  if (this.firstVastPlayerPlayRequest) {
    this.firstVastPlayerPlayRequest = false;
  }
  Utils.createApiEvent.call(this, 'adloaded');
  TRACKING_EVENTS.dispatch.call(this, 'loaded');
};
var _onClickThrough = function _onClickThrough(event) {
  if (event) {
    event.stopPropagation();
  }
  if (!ENV.isMobile) {
    console.log("".concat(FW.consolePrepend, " Opening clickthrough URL at ").concat(this.creative.clickThroughUrl), FW.consoleStyle, '');
    FW.openWindow(this.creative.clickThroughUrl);
  }
  this.pause();
  Utils.createApiEvent.call(this, 'adclick');
  TRACKING_EVENTS.dispatch.call(this, 'clickthrough');
};
var _onPlaybackError = function _onPlaybackError(event) {
  // https://www.w3.org/TR/html50/embedded-content-0.html#mediaerror
  // MEDIA_ERR_SRC_NOT_SUPPORTED is sign of fatal error
  // other errors may produce non-fatal error in the browser so we do not
  // act upon them
  if (event && event.target) {
    var videoElement = event.target;
    if (videoElement.error && FW.isNumber(videoElement.error.code)) {
      var errorCode = videoElement.error.code;
      var errorMessage = '';
      if (typeof videoElement.error.message === 'string') {
        errorMessage = videoElement.error.message;
      }
      console.log("".concat(FW.consolePrepend, " Error on video element with code ").concat(errorCode.toString(), " and message ").concat(errorMessage), FW.consoleStyle, '');
      console.log("".concat(FW.consolePrepend, " error type is ").concat(HTML5_MEDIA_ERROR_TYPES[errorCode] ? HTML5_MEDIA_ERROR_TYPES[errorCode] : 'unknown type'), FW.consoleStyle, '');

      // EDIA_ERR_SRC_NOT_SUPPORTED (numeric value 4)
      if (errorCode === 4) {
        Utils.processVastErrors.call(this, 401, true);
      }
    }
  }
};
var _onContextMenu = function _onContextMenu(event) {
  if (event) {
    event.stopPropagation();
    event.preventDefault();
  }
};
var _updateWaitingForCanBeSkippedUI = function _updateWaitingForCanBeSkippedUI(delta) {
  if (Math.round(delta) > 0) {
    this.skipWaiting.textContent = this.params.labels.skipMessage + ' ' + Math.round(delta) + 's';
  }
};
var _onTimeupdateCheckSkip = function _onTimeupdateCheckSkip() {
  if (this.skipButton.style.display === 'none') {
    FW.setStyle(this.skipButton, {
      display: 'block'
    });
  }
  this.vastPlayerCurrentTime = this.vastPlayer.currentTime;
  if (FW.isNumber(this.vastPlayerCurrentTime) && this.vastPlayerCurrentTime > 0) {
    if (this.vastPlayerCurrentTime >= this.creative.skipoffset) {
      this.vastPlayer.removeEventListener('timeupdate', this.onTimeupdateCheckSkip);
      this.skippableAdCanBeSkipped = true;
      Utils.createApiEvent.call(this, 'adskippablestatechanged');
    } else if (this.creative.skipoffset - this.vastPlayerCurrentTime > 0) {
      _updateWaitingForCanBeSkippedUI.call(this, this.creative.skipoffset - this.vastPlayerCurrentTime);
    }
  }
};
var _onClickSkip = function _onClickSkip(event) {
  if (event) {
    event.stopPropagation();
    if (event.type === 'touchend') {
      event.preventDefault();
    }
  }
  if (this.skippableAdCanBeSkipped) {
    // create API event
    Utils.createApiEvent.call(this, 'adskipped');
    // request ping for skip event
    TRACKING_EVENTS.dispatch.call(this, 'skip');
    // resume content
    VAST_PLAYER.resumeContent.call(this);
  }
};
var _appendSkip = function _appendSkip() {
  this.skipButton = document.createElement('div');
  this.skipButton.className = 'rmp-ad-container-skip';
  FW.setStyle(this.skipButton, {
    display: 'none'
  });
  Utils.makeButtonAccessible(this.skipButton, this.params.labels.skipMessage);
  this.skipWaiting = document.createElement('div');
  this.skipWaiting.className = 'rmp-ad-container-skip-waiting';
  _updateWaitingForCanBeSkippedUI.call(this, this.creative.skipoffset);
  FW.setStyle(this.skipWaiting, {
    display: 'block'
  });
  this.skipMessage = document.createElement('div');
  this.skipMessage.className = 'rmp-ad-container-skip-message';
  this.skipMessage.textContent = this.params.labels.skipMessage;
  FW.setStyle(this.skipMessage, {
    display: 'none'
  });
  this.skipIcon = document.createElement('div');
  this.skipIcon.className = 'rmp-ad-container-skip-icon';
  FW.setStyle(this.skipIcon, {
    display: 'none'
  });
  this.onClickSkip = _onClickSkip.bind(this);
  this.skipButton.addEventListener('click', this.onClickSkip);
  this.skipButton.addEventListener('touchend', this.onClickSkip);
  this.skipButton.appendChild(this.skipWaiting);
  this.skipButton.appendChild(this.skipMessage);
  this.skipButton.appendChild(this.skipIcon);
  this.adContainer.appendChild(this.skipButton);
  this.onTimeupdateCheckSkip = _onTimeupdateCheckSkip.bind(this);
  this.vastPlayer.addEventListener('timeupdate', this.onTimeupdateCheckSkip);
};
var _onHlsJSError = function _onHlsJSError(event, data) {
  if (data.fatal) {
    switch (data.type) {
      case Hls.ErrorTypes.NETWORK_ERROR:
        // try to recover network error
        this.hlsJS[this.hlsJSIndex].startLoad();
        break;
      case Hls.ErrorTypes.MEDIA_ERROR:
        this.hlsJS[this.hlsJSIndex].recoverMediaError();
        break;
      default:
        Utils.processVastErrors.call(this, 900, true);
        break;
    }
  }
};
LINEAR.update = function (url, type) {
  var _this2 = this;
  console.log("".concat(FW.consolePrepend, " update vast player for linear creative of type ").concat(type, " located at ").concat(url), FW.consoleStyle, '');
  this.vastPlayer = this.params.preAdPlay(this.vastPlayer);
  this.onDurationChange = _onDurationChange.bind(this);
  this.vastPlayer.addEventListener('durationchange', this.onDurationChange, {
    once: true
  });

  // when creative is loaded play it
  this.onLoadedmetadataPlay = _onLoadedmetadataPlay.bind(this);
  this.vastPlayer.addEventListener('loadedmetadata', this.onLoadedmetadataPlay, {
    once: true
  });

  // prevent built in menu to show on right click
  this.onContextMenu = _onContextMenu.bind(this);
  this.vastPlayer.addEventListener('contextmenu', this.onContextMenu);
  this.onPlaybackError = _onPlaybackError.bind(this);

  // start creativeLoadTimeout
  this.creativeLoadTimeoutCallback = setTimeout(function () {
    Utils.processVastErrors.call(_this2, 402, true);
  }, this.params.creativeLoadTimeout);
  // load ad asset
  Utils.createApiEvent.call(this, 'adsourceswitching');
  this.contentPlayer.addEventListener('error', this.onPlaybackError);
  this.contentPlayer.src = url;
  Utils.createApiEvent.call(this, 'adsourceswitched');

  // skippable - only where vast player is different from
  // content player
  if (this.creative.isSkippableAd) {
    _appendSkip.call(this);
  }
};
LINEAR.parse = function (icons, adParameters, mediaFiles) {
  // check for AdParameters tag in case we have a VPAID creative
  this.adParametersData = '';
  if (adParameters !== null) {
    this.adParametersData = adParameters;
  }
  var mediaFileItems = [];
  for (var i = 0; i < mediaFiles.length; i++) {
    var currentMediaFile = mediaFiles[i];
    var mediaFileValue = currentMediaFile.fileURL;
    var type = currentMediaFile.mimeType;
    if (mediaFileValue === null || type === null) {
      continue;
    }
    var newMediaFileItem = {};
    newMediaFileItem.url = mediaFileValue;
    newMediaFileItem.type = type;
    if (currentMediaFile.codec !== null) {
      newMediaFileItem.codec = currentMediaFile.codec;
    }
    newMediaFileItem.width = currentMediaFile.width;
    newMediaFileItem.height = currentMediaFile.height;
    newMediaFileItem.bitrate = currentMediaFile.bitrate;
    mediaFileItems.push(newMediaFileItem);
  }
  // we support HLS; MP4; WebM: VPAID so let us fecth for those
  var creatives = [];
  for (var j = 0; j < mediaFileItems.length; j++) {
    var currentMediaFileItem = mediaFileItems[j];
    var _type = currentMediaFileItem.type;
    var url = currentMediaFileItem.url;

    // we have HLS > use hls.js where no native support for HLS is available or native HLS otherwise (Apple devices mainly)
    if (_type === 'application/vnd.apple.mpegurl' && (ENV.checkCanPlayType(_type) || typeof window.Hls !== 'undefined' && Hls.isSupported())) {
      VAST_PLAYER.append.call(this, url, _type);
      this.creative.type = _type;
      return;
    }
    // we have DASH and DASH is natively supported > use DASH
    if (ENV.checkCanPlayType('application/dash+xml')) {
      VAST_PLAYER.append.call(this, url, _type);
      this.creative.type = _type;
      return;
    }
    // we gather MP4, WebM, OGG and remaining files
    creatives.push(currentMediaFileItem);
  }
  var retainedCreatives = [];
  // first we check for the common formats below ...
  var __filterCommonCreatives = function __filterCommonCreatives(i, creative) {
    if (creative.codec && creative.type === COMMON_VIDEO_FORMATS[i]) {
      return ENV.checkCanPlayType(creative.type, creative.codec);
    } else if (creative.type === COMMON_VIDEO_FORMATS[i]) {
      return ENV.checkCanPlayType(creative.type);
    }
    return false;
  };
  for (var k = 0; k < COMMON_VIDEO_FORMATS.length; k++) {
    retainedCreatives = creatives.filter(__filterCommonCreatives.bind(null, k));
    if (retainedCreatives.length > 0) {
      break;
    }
  }
  // ... if none of the common format work, then we check for exotic format
  // first we check for those with codec information as it provides more accurate support indication ...
  if (retainedCreatives.length === 0) {
    var __filterCodecCreatives = function __filterCodecCreatives(codec, type, creative) {
      return creative.codec === codec && creative.type === type;
    };
    creatives.forEach(function (creative) {
      if (creative.codec && creative.type && ENV.checkCanPlayType(creative.type, creative.codec)) {
        retainedCreatives = creatives.filter(__filterCodecCreatives.bind(null, creative.codec, creative.type));
      }
    });
  }
  // ... if codec information are not available then we go first type matching
  if (retainedCreatives.length === 0) {
    var __filterTypeCreatives = function __filterTypeCreatives(type, creative) {
      return creative.type === type;
    };
    creatives.forEach(function (creative) {
      if (creative.type && ENV.checkCanPlayType(creative.type)) {
        retainedCreatives = creatives.filter(__filterTypeCreatives.bind(null, creative.type));
      }
    });
  }

  // still no match for supported format - we exit
  if (retainedCreatives.length === 0) {
    // None of the MediaFile provided are supported by the player
    Utils.processVastErrors.call(this, 403, true);
    return;
  }

  // sort supported creatives by width
  retainedCreatives.sort(function (a, b) {
    return a.width - b.width;
  });
  console.log("".concat(FW.consolePrepend, " available linear creative follows"), FW.consoleStyle, '');
  console.log(retainedCreatives);

  // we have files matching device capabilities
  // select the best one based on player current width
  var finalCreative;
  var validCreativesByWidth = [];
  var validCreativesByBitrate = [];
  if (retainedCreatives.length > 1) {
    var containerWidth = FW.getWidth(this.container) * ENV.devicePixelRatio;
    var containerHeight = FW.getHeight(this.container) * ENV.devicePixelRatio;
    if (containerWidth > 0 && containerHeight > 0) {
      validCreativesByWidth = retainedCreatives.filter(function (creative) {
        return containerWidth >= creative.width && containerHeight >= creative.height;
      });
    }
    console.log("".concat(FW.consolePrepend, " validCreativesByWidth follow"), FW.consoleStyle, '');
    console.log(validCreativesByWidth);

    // if no match by size
    if (validCreativesByWidth.length === 0) {
      validCreativesByWidth = [retainedCreatives[0]];
    }

    // filter by bitrate to provide best quality
    var rmpConnection = new RmpConnection();
    var availableBandwidth = rmpConnection.bandwidthData.estimate;
    console.log("".concat(FW.consolePrepend, " availableBandwidth is ").concat(availableBandwidth, " Mbps"), FW.consoleStyle, '');
    if (availableBandwidth > -1 && validCreativesByWidth.length > 1) {
      // sort supported creatives by bitrates
      validCreativesByWidth.sort(function (a, b) {
        return a.bitrate - b.bitrate;
      });
      // convert to kbps
      availableBandwidth = Math.round(availableBandwidth * 1000);
      validCreativesByBitrate = validCreativesByWidth.filter(function (creative) {
        return availableBandwidth >= creative.bitrate;
      });
      console.log("".concat(FW.consolePrepend, " validCreativesByBitrate follow"), FW.consoleStyle, '');
      console.log(validCreativesByBitrate);

      // pick max available bitrate
      finalCreative = validCreativesByBitrate[validCreativesByBitrate.length - 1];
    }
  }

  // if no match by bitrate
  if (!finalCreative) {
    if (validCreativesByWidth.length > 0) {
      finalCreative = validCreativesByWidth[validCreativesByWidth.length - 1];
    } else {
      retainedCreatives.sort(function (a, b) {
        return a.bitrate - b.bitrate;
      });
      finalCreative = retainedCreatives[retainedCreatives.length - 1];
    }
  }
  console.log("".concat(FW.consolePrepend, " selected linear creative follows"), FW.consoleStyle, '');
  console.log(finalCreative);
  this.creative.mediaUrl = finalCreative.url;
  this.creative.height = finalCreative.height;
  this.creative.width = finalCreative.width;
  this.creative.type = finalCreative.type;
  VAST_PLAYER.append.call(this, finalCreative.url, finalCreative.type);
};
export default LINEAR;