/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
export function isString(val) {
  return typeof val === 'string' || val instanceof String;
}
/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
export function isNumberId(val) {
  return Number.isInteger(val);
}
export function isNumber(val) {
  return !isNaN(val) && typeof val === 'number';
}
export function isFunction(value) {
  return typeof value === 'function';
}