import { createCompanionAd } from '../companion_ad';
import { createCreativeCompanion } from '../creative/creative_companion';
import { parserUtils } from './parser_utils';

/**
 * This module provides methods to parse a VAST CompanionAd Element.
 */

/**
 * Parses a CompanionAd.
 * @param  {Object} creativeElement - The VAST CompanionAd element to parse.
 * @param  {Object} creativeAttributes - The attributes of the CompanionAd (optional).
 * @return {Object} creative - The creative object.
 */
export function parseCreativeCompanion(creativeElement, creativeAttributes) {
  var creative = createCreativeCompanion(creativeAttributes);
  creative.required = creativeElement.getAttribute('required') || null;
  creative.variations = parserUtils.childrenByName(creativeElement, 'Companion').map(function (companionResource) {
    var companionAd = createCompanionAd(parserUtils.parseAttributes(companionResource));
    companionAd.htmlResources = parserUtils.childrenByName(companionResource, 'HTMLResource').reduce(function (urls, resource) {
      var url = parserUtils.parseNodeText(resource);
      return url ? urls.concat(url) : urls;
    }, []);
    companionAd.iframeResources = parserUtils.childrenByName(companionResource, 'IFrameResource').reduce(function (urls, resource) {
      var url = parserUtils.parseNodeText(resource);
      return url ? urls.concat(url) : urls;
    }, []);
    companionAd.staticResources = parserUtils.childrenByName(companionResource, 'StaticResource').reduce(function (urls, resource) {
      var url = parserUtils.parseNodeText(resource);
      return url ? urls.concat({
        url: url,
        creativeType: resource.getAttribute('creativeType') || null
      }) : urls;
    }, []);
    companionAd.altText = parserUtils.parseNodeText(parserUtils.childByName(companionResource, 'AltText')) || null;
    var trackingEventsElement = parserUtils.childByName(companionResource, 'TrackingEvents');
    if (trackingEventsElement) {
      parserUtils.childrenByName(trackingEventsElement, 'Tracking').forEach(function (trackingElement) {
        var eventName = trackingElement.getAttribute('event');
        var trackingURLTemplate = parserUtils.parseNodeText(trackingElement);
        if (eventName && trackingURLTemplate) {
          if (!Array.isArray(companionAd.trackingEvents[eventName])) {
            companionAd.trackingEvents[eventName] = [];
          }
          companionAd.trackingEvents[eventName].push(trackingURLTemplate);
        }
      });
    }
    companionAd.companionClickTrackingURLTemplates = parserUtils.childrenByName(companionResource, 'CompanionClickTracking').map(function (clickTrackingElement) {
      return {
        id: clickTrackingElement.getAttribute('id') || null,
        url: parserUtils.parseNodeText(clickTrackingElement)
      };
    });
    companionAd.companionClickThroughURLTemplate = parserUtils.parseNodeText(parserUtils.childByName(companionResource, 'CompanionClickThrough')) || null;
    var adParametersElement = parserUtils.childByName(companionResource, 'AdParameters');
    if (adParametersElement) {
      companionAd.adParameters = parserUtils.parseNodeText(adParametersElement);
      companionAd.xmlEncoded = adParametersElement.getAttribute('xmlEncoded') || null;
    }
    return companionAd;
  });
  return creative;
}