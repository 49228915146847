var _excluded = ["name"];
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import { AppInstance } from '@lightningjs/sdk/src/Application';
import isObject from 'lodash/isObject';
import { SettingsStore } from '../lib';
import { baseTheme } from './baseTheme';
import { excitementTheme } from './excitementTheme';
import { poppyTheme } from './poppyTheme';
export var Themes = [baseTheme, excitementTheme, poppyTheme];
var globalTheme;
export function setGlobalTheme(theme) {
  globalTheme = theme;
}
export function getStoredTheme() {
  if (isObject(AppInstance) && AppInstance._theme) {
    // eslint-disable-next-line
    return AppInstance._theme;
  } else if (globalTheme) {
    return globalTheme;
  }
  var themeName = SettingsStore.get('theme');
  var foundTheme = Themes.find(function (_ref) {
    var name = _ref.name;
    return name === themeName;
  });
  return foundTheme !== null && foundTheme !== void 0 ? foundTheme : baseTheme;
}
export function getThemeFonts() {
  var out = Object.values(Themes).reduce(function (acc, theme) {
    var _theme$fonts = theme.fonts,
      name = _theme$fonts.name,
      rest = _objectWithoutProperties(_theme$fonts, _excluded);
    acc = acc.concat(Object.entries(rest).map(function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
        family = _ref3[0],
        url = _ref3[1];
      return {
        family: "".concat(name).concat(family),
        url: url
      };
    }));
    return acc;
  }, []);
  return out;
}