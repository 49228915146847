import { isFunction, isNumber, isString } from './utils';
export function hasHeight(x) {
  return x && isNumber(x.height);
}
export function canGetFocusCoordinates(x) {
  return x && isFunction(x.currentFocusCoordinates);
}
export function restoresState(x) {
  return x != null && isFunction(x.restoreState);
}
export function setsByX(x) {
  return x && isFunction(x.setClosestByX);
}
export function getsFocusedCoords(x) {
  return x && isFunction(x.getFocusedCoords);
}
export function setsByY(x) {
  return x && isFunction(x.setClosestByY);
}
export function isActionableRowButtonConfig(x) {
  return x && isString(x.buttonText) && x.action && isString(x.action.action);
}