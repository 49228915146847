import { isGoodString } from '../lightning-tools';
import { isSearchIntent, isTVEpisodeEntity, isTVSeasonEntity, isTVSeriesEntity } from './types';
export function isEntityIntent(x) {
  return x.action === 'entity';
}
export function paramsRoutePath(params) {
  var _params$discovery;
  if ((_params$discovery = params.discovery) !== null && _params$discovery !== void 0 && _params$discovery.navigateTo) {
    switch (params.discovery.navigateTo.action) {
      case 'home':
        return 'home';
      case 'search':
        return 'search';
      case 'entity':
        {
          var entityData = params.discovery.navigateTo.data;
          if (isTVEpisodeEntity(entityData)) {
            return "details/tv/".concat(entityData.seriesId);
          } else if (isTVSeriesEntity(entityData)) {
            return "details/tv/".concat(entityData.entityId);
          } else if (isTVSeasonEntity(entityData)) {
            return "details/tv/".concat(entityData.seriesId);
          }
          return "details/movie/".concat(entityData.entityId);
        }
      case 'playback':
        {
          var playbackData = params.discovery.navigateTo.data;
          if (isTVEpisodeEntity(playbackData)) {
            return "player/tv/".concat(playbackData.seriesId, "/episode/").concat(playbackData.entityId);
          } else {
            return "player/movie/".concat(playbackData.entityId);
          }
        }
      default:
        return null;
    }
  } else {
    return null;
  }
}
function paramsQueryParser(x) {
  var _x$discovery;
  var nav = (_x$discovery = x.discovery) === null || _x$discovery === void 0 ? void 0 : _x$discovery.navigateTo;
  if (isSearchIntent(nav)) {
    var _nav$data, _nav$data2;
    var query = (_nav$data = nav.data) !== null && _nav$data !== void 0 && _nav$data.query ? (_nav$data2 = nav.data) === null || _nav$data2 === void 0 ? void 0 : _nav$data2.query.trim() : '';
    if (isGoodString(query)) {
      return {
        query: query
      };
    }
  }
  return {};
}
export function paramsRoute(bootParams) {
  var path = paramsRoutePath(bootParams);
  var params = paramsQueryParser(bootParams);
  return {
    path: path,
    params: params
  };
}