import { isString, isNumber } from './utils';
export function isMediaDetailsExtra(x) {
  return x.type === 'extra';
}
export function isExtra(details) {
  return details != null && details.type === 'extra';
}
export function isID(x) {
  return isNumber(x) || isString(x);
}
export function isShowContentItem(x) {
  return x.type === 'tv';
}
export var availableContentPaths = ['details', 'player'];
// export const VideoViewStates: VideoViewEvent['state'][] = [
//   'playing',
//   'waiting',
//   'completed',
// ]
export var VideoViewProgress = ['0%', '25%', '50%', '75%', '100%'];
export function isVideoRenditionChangeEvent(v) {
  return v.video_event === 'rendition_change';
}
export function isVideoRateChangeEvent(v) {
  return v.video_event === 'rate_change';
}
export function isEpisodePlayerPaylaod(x) {
  return x.episode !== undefined;
}