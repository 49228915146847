var _excluded = ["components"];
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import deepmerge from 'ts-deepmerge';
export function isNavigationPayload(p) {
  if (!p || isString(p)) return false;
  return isString(p.path) && ['extra', 'trailer', 'episode'].includes(p.type);
}
export function createPalette(hues) {
  var colors = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var darks = hues.darks;
  return _objectSpread(_objectSpread({
    primaryBackground: darks[600],
    shadow: darks[500],
    errorText: '#e4420a',
    goodText: '#005500',
    background: darks[900],
    backgroundGradient: darks[700],
    text: darks[50],
    buttonBackground: darks[400],
    buttonText: darks[50],
    buttonBorder: darks[400],
    buttonHighlightBackground: darks[600],
    buttonHighlightText: darks[900],
    cardHighlightText: darks[900],
    buttonHighlightBorder: hues.highlights[500],
    primaryHighlight: hues.highlights[500],
    currentHighlight: hues.highlights[200],
    currentHighlightText: darks[50]
  }, colors), hues);
}
export function createThemeConfig(config) {
  var _config$components = config.components,
    components = _config$components === void 0 ? {} : _config$components,
    rest = _objectWithoutProperties(config, _excluded);
  var baseComponentConfig = {
    PlayControlConfig: {
      playBackground: config.palette['primaryBackground'],
      backgroundAlphaUnfocused: 0.2,
      backgroundAlphaFocused: 0.6,
      scale: 2
    },
    MainMenuButtonConfig: {
      radius: 8,
      backgroundColor: config.palette['primaryBackground']
    },
    ButtonConfig: {
      radius: 12
    },
    MainMenuConfig: {
      radius: 12,
      backgroundColor: config.palette['primaryBackground']
    },
    HeroWidgetItemConfig: {
      colorLeft: config.palette.darks[500],
      colorRight: config.palette.darks[800]
    },
    SimpleCardConfig: {
      backgroundColor: config.palette.buttonBackground,
      radius: 18,
      stroke: 4,
      shadowColor: config.palette.darks[800],
      largeRadius: 24,
      imageRadius: 12
    },
    FullPageBackground: {
      backgroundColor: config.palette.background
    },
    BoxCardConfig: {
      radius: 16
    },
    VideoCardConfig: {
      radius: 16,
      vignetteColor: config.palette.darks[900]
    }
  };
  var finalComponents = deepmerge(baseComponentConfig, components);
  var finalConfig = _objectSpread({
    components: finalComponents
  }, rest);
  return finalConfig;
}
export function isHideElement(x) {
  return x && isFunction(x.hide);
}
export function isShowElement(x) {
  return x && isFunction(x.show);
}
export function isShowHideElement(x) {
  return x && isHideElement(x) && isShowElement(x);
}