function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { z } from 'zod';
function isTimestamp(x) {
  return x && x.toDate && typeof x.toDate === 'function';
}
var publishable = z.object({
  _startDate: z.preprocess(function (val) {
    if (isTimestamp(val)) {
      return val.toDate();
    } else {
      return val;
    }
  }, z.date()),
  weight: z.number().default(0),
  _endDate: z.preprocess(function (val) {
    if (isTimestamp(val)) {
      return val.toDate();
    } else {
      return val;
    }
  }, z.date()).optional().refine(function (val) {
    console.info('Parsing end date of %s', val);
    return val == null || val.getTime() > new Date().getTime();
  }, {
    message: 'End Date must be undefined or later than right now'
  }),
  _published: z.boolean().default(false)
});
export var heroConfigSchema = z.object({
  _id: z.string(),
  wideImage: z.string().url(),
  details: z.string(),
  title: z.string(),
  description: z.string(),
  externalId: z.string()
}).merge(publishable);
export var promotedContentConfigSchema = z.object({
  externalId: z.string(),
  title: z.string(),
  promotedContentType: z.preprocess(function (val) {
    return String(val).toLowerCase().replace(/\s+/g, '-');
  }, z.enum(['category', 'content-item']))
}).merge(publishable);
export function makePublicRecord(x) {
  return Object.entries(x).reduce(function (acc, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    if (key.indexOf('_') !== 0) acc[key] = value;
    return acc;
  }, {});
}