export function createCreative() {
  var creativeAttributes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    id: creativeAttributes.id || null,
    adId: creativeAttributes.adId || null,
    sequence: creativeAttributes.sequence || null,
    apiFramework: creativeAttributes.apiFramework || null,
    universalAdIds: [],
    creativeExtensions: []
  };
}