import { Settings } from '@lightningjs/sdk';
import mux from 'mux-embed';
import { Debugger } from '../../../lib';
import { hlsLoader } from './hls';
var debug = new Debugger('PlayerPlane:OptsLoader');
export var optsLoader = function optsLoader(details) {
  return function (url, video) {
    var loader = hlsLoader(details);
    var adsEnabled = Settings.get('app', 'ADS_ENABLED');
    debug.info('Ads Enabled?', adsEnabled);
    var APP_MUX_ENV_KEY = Settings.get('app', 'APP_MUX_ENV_KEY');
    var APP_MUX_DEBUG = Settings.get('app', 'APP_MUX_DEBUG');
    var APP_SHORT_NAME = Settings.get('app', 'APP_SHORT_NAME', 'lightning-engine');
    if (APP_MUX_ENV_KEY) {
      var playerInitTime = mux.utils.now();
      var data = {
        env_key: APP_MUX_ENV_KEY,
        // required
        // Metadata fields
        player_name: APP_SHORT_NAME,
        // any arbitrary string you want to use to identify this player
        player_init_time: playerInitTime
      };
      if (details.media || details.content) {
        var _media$id, _content$title;
        var _details$media = details.media,
          media = _details$media === void 0 ? null : _details$media,
          _details$content = details.content,
          content = _details$content === void 0 ? null : _details$content;
        if (content) data['video_id'] = (_media$id = media === null || media === void 0 ? void 0 : media.id) !== null && _media$id !== void 0 ? _media$id : content.id;
        var title = (_content$title = content === null || content === void 0 ? void 0 : content.title) !== null && _content$title !== void 0 ? _content$title : media === null || media === void 0 ? void 0 : media.button_text;
        if (title) data['video_title'] = title;
      }
      var setup = {
        debug: APP_MUX_DEBUG === true || APP_MUX_DEBUG === 'true',
        // Never can tell how these get returned
        data: data
      };
      debug.info('Setup', setup);
      mux.monitor(video, setup);
    }
    return loader(url, video);
  };
};