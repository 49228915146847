import { Launch } from '@lightningjs/sdk';
// Import the functions you need from the SDKs you need

import { App } from './tfc_app';
export default function LaunchApp(appSettings, platformSettings) {
  var appData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  console.info('Launching with settings', JSON.stringify({
    appSettings: appSettings,
    platformSettings: platformSettings,
    appData: appData
  }, null, 2));
  return Launch(App, appSettings, platformSettings, appData);
}