import { createPalette, createThemeConfig } from '@adiffengine/elements';
var darks = {
  50: '#e4e4e5',
  100: '#babbbf',
  200: '#8d8e94',
  300: '#5f6169',
  400: '#3c3f49',
  500: '#1a1d29',
  600: '#171a24',
  700: '#13151f',
  800: '#0f1119',
  900: '#080a0f'
};
var highlights = {
  50: '#FCE7FD',
  100: '#F7C2FB',
  200: '#F299F8',
  300: '#f9ef8b',
  400: '#fff167',
  500: '#fce820',
  600: '#f0da00',
  700: '#d7c403',
  800: '#ffe800',
  900: '#ffe800'
};
export var rfcTheme = createThemeConfig({
  name: 'TFC',
  palette: createPalette({
    darks: darks,
    highlights: highlights
  }, {
    text: '#F9F9F9',
    buttonText: highlights[50],
    background: darks[700],
    backgroundGradient: darks[400],
    primaryHighlight: highlights[200]
  }),
  logo_size: 400,
  logo: 'images/tfc-logo.png',
  fonts: {
    name: 'Prompt',
    Text: 'fonts/Prompt/Prompt-Light.ttf',
    Regular: 'fonts/Prompt/Prompt-Regular.ttf',
    Bold: 'fonts/Prompt/Prompt-SemiBold.ttf',
    ExtraBold: 'fonts/Prompt/Prompt-Bold.ttf'
  },
  components: {
    VideoCardConfig: {
      radius: 24,
      textHighlightColor: highlights[50]
    },
    MainMenuConfig: {
      radius: 12,
      icon: 'images/tfc-icon-menu.png',
      logo: 'images/tfc-logo-menu.png'
    },
    SimpleCardConfig: {
      radius: 24,
      imageRadius: 12
    },
    BoxCardConfig: {
      radius: 24,
      textHighlightColor: highlights[50]
    }
  }
});