/*
 * If not stated otherwise in this file or this component's LICENSE file the
 * following copyright and licenses apply:
 *
 * Copyright 2020 Metrological
 *
 * Licensed under the Apache License, Version 2.0 (the License);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Log, Metrics, Settings, Ads } from '@lightningjs/sdk';
import VideoTexture from './VideoTexture';
import { AppInstance } from '@lightningjs/sdk/src/Application';
import { events } from './events';
export var mediaUrl = function mediaUrl(url) {
  return url;
};
var videoEl;
var videoTexture;
var metrics;
var _consumer;
var precision = 1;
var textureMode = false;
export var initVideoPlayer = function initVideoPlayer(config) {
  if (config.mediaUrl) {
    mediaUrl = config.mediaUrl;
  }
};
// todo: add this in a 'Registry' plugin
// to be able to always clean this up on app close
var eventHandlers = {};
var state = {
  adsEnabled: false,
  playing: false,
  _playingAds: false,
  get playingAds() {
    return this._playingAds;
  },
  set playingAds(val) {
    if (this._playingAds !== val) {
      this._playingAds = val;
      fireOnConsumer(val === true ? 'AdStart' : 'AdEnd');
    }
  },
  skipTime: false,
  playAfterSeek: null
};
var hooks = {
  play: function play() {
    state.playing = true;
  },
  pause: function pause() {
    state.playing = false;
  },
  seeked: function seeked() {
    state.playAfterSeek === true && videoPlayerPlugin.play();
    state.playAfterSeek = null;
  },
  abort: function abort() {
    _deregisterEventListeners();
  }
};
var withPrecision = function withPrecision(val) {
  return Math.round(precision * val) + 'px';
};
var fireOnConsumer = function fireOnConsumer(event, args) {
  if (_consumer) {
    _consumer.fire('$videoPlayer' + event, args, videoEl.currentTime);
    _consumer.fire('$videoPlayerEvent', event, args, videoEl.currentTime);
  }
};
var fireHook = function fireHook(event, args) {
  hooks[event] && typeof hooks[event] === 'function' && hooks[event].call(null, event, args);
};
var customLoader = null;
var customUnloader = null;
var loader = function loader(url, videoEl, config) {
  return customLoader && typeof customLoader === 'function' ? customLoader(url, videoEl, config) : new Promise(function (resolve) {
    url = mediaUrl(url);
    videoEl.setAttribute('src', url);
    videoEl.load();
    resolve();
  });
};
var unloader = function unloader(videoEl) {
  return customUnloader && typeof customUnloader === 'function' ? customUnloader(videoEl) : new Promise(function (resolve) {
    videoEl.removeAttribute('src');
    videoEl.load();
    resolve();
  });
};
export var setupVideoTag = function setupVideoTag() {
  var videoEls = document.getElementsByTagName('video');
  if (videoEls && videoEls.length) {
    return videoEls[0];
  } else {
    var _videoEl = document.createElement('video');
    var platformSettingsWidth = Settings.get('platform', 'width') ? Settings.get('platform', 'width') : 1920;
    var platformSettingsHeight = Settings.get('platform', 'height') ? Settings.get('platform', 'height') : 1080;
    _videoEl.setAttribute('id', 'video-player');
    _videoEl.setAttribute('width', withPrecision(platformSettingsWidth));
    _videoEl.setAttribute('height', withPrecision(platformSettingsHeight));
    _videoEl.style.position = 'absolute';
    _videoEl.style.zIndex = '1';
    _videoEl.style.display = 'none';
    _videoEl.style.visibility = 'hidden';
    _videoEl.style.top = withPrecision(0);
    _videoEl.style.left = withPrecision(0);
    _videoEl.style.width = withPrecision(platformSettingsWidth);
    _videoEl.style.height = withPrecision(platformSettingsHeight);
    document.body.appendChild(_videoEl);
    return _videoEl;
  }
};
export var setUpVideoTexture = function setUpVideoTexture() {
  if (!AppInstance.tag('VideoTexture')) {
    var el = AppInstance.stage.c({
      type: VideoTexture(),
      ref: 'VideoTexture',
      zIndex: 0,
      videoEl: videoEl
    });
    AppInstance.childList.addAt(el, 0);
  }
  return AppInstance.tag('VideoTexture');
};
var eventsPaused = false;
var _registerEventListeners = function registerEventListeners() {
  Log.info('VideoPlayer', 'Registering event listeners');
  Object.keys(events).forEach(function (event) {
    var handler = function handler(e) {
      if (!eventsPaused) {
        // Fire a metric for each event (if it exists on the metrics object)
        if (metrics && metrics[event] && typeof metrics[event] === 'function') {
          metrics[event]({
            currentTime: videoEl.currentTime
          });
        }
        // fire an internal hook
        fireHook(event, {
          videoElement: videoEl,
          event: e
        });

        // fire the event (with human friendly event name) to the consumer of the VideoPlayer
        fireOnConsumer(events[event], {
          videoElement: videoEl,
          event: e
        });
      }
    };
    eventHandlers[event] = handler;
    videoEl.addEventListener(event, handler);
  });
};
var _deregisterEventListeners = function deregisterEventListeners() {
  Log.info('VideoPlayer', 'Deregistering event listeners');
  Object.keys(eventHandlers).forEach(function (event) {
    videoEl.removeEventListener(event, eventHandlers[event]);
  });
  eventHandlers = {};
};
var videoPlayerPlugin = {
  consumer: function consumer(component) {
    _consumer = component;
  },
  loader: function loader(loaderFn) {
    customLoader = loaderFn;
  },
  unloader: function unloader(unloaderFn) {
    customUnloader = unloaderFn;
  },
  position: function position() {
    var top = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var left = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    videoEl.style.left = withPrecision(left);
    videoEl.style.top = withPrecision(top);
    if (textureMode === true) {
      videoTexture.position(top, left);
    }
  },
  size: function size() {
    var width = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1920;
    var height = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1080;
    videoEl.style.width = withPrecision(width);
    videoEl.style.height = withPrecision(height);
    videoEl.width = parseFloat(videoEl.style.width);
    videoEl.height = parseFloat(videoEl.style.height);
    if (textureMode === true) {
      videoTexture.size(width, height);
    }
  },
  area: function area() {
    var top = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var right = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1920;
    var bottom = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1080;
    var left = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    this.position(top, left);
    this.size(right - left, bottom - top);
  },
  open: function open(url) {
    var _this = this;
    var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    eventsPaused = false;
    if (!this.canInteract) return;
    metrics = Metrics.media(url);
    this.hide();
    _deregisterEventListeners();
    if (this.src == url) {
      this.clear().then(this.open(url, config));
    } else {
      var adConfig = {
        enabled: state.adsEnabled,
        duration: 300
      };
      if (config.videoId) {
        adConfig.caid = config.videoId;
      }
      Ads.get(adConfig, _consumer).then(function (ads) {
        state.playingAds = true;
        ads.prerolls().then(function () {
          state.playingAds = false;
          loader(url, videoEl, config).then(function () {
            _registerEventListeners();
            _this.show();
            return _this.play();
          }).catch(function (e) {
            fireOnConsumer('error', {
              videoElement: videoEl,
              event: e
            });
          });
        });
      });
    }
  },
  reload: function reload() {
    if (!this.canInteract) return;
    var url = videoEl.getAttribute('src');
    this.close();
    this.open(url);
  },
  close: function close() {
    var _this2 = this;
    Ads.cancel();
    if (state.playingAds) {
      state.playingAds = false;
      Ads.stop();
      // call self in next tick
      setTimeout(function () {
        _this2.close();
      });
    }
    if (!this.canInteract) return;
    this.clear();
    this.hide();
    _deregisterEventListeners();
  },
  clear: function clear() {
    if (!this.canInteract) return;
    // pause the video first to disable sound
    this.pause();
    if (textureMode === true) videoTexture.stop();
    return unloader(videoEl).then(function () {
      fireOnConsumer('Clear', {
        videoElement: videoEl
      });
    });
  },
  play: function play() {
    if (!this.canInteract) return;
    if (textureMode === true) videoTexture.start();
    return executeAsPromise(videoEl.play, null, videoEl).catch(function (e) {
      fireOnConsumer('error', {
        videoElement: videoEl,
        event: e
      });
    });
  },
  pause: function pause() {
    if (!this.canInteract) return;
    videoEl.pause();
  },
  playPause: function playPause() {
    if (!this.canInteract) return;
    this.playing === true ? this.pause() : this.play();
  },
  mute: function mute() {
    var muted = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    if (!this.canInteract) return;
    videoEl.muted = muted;
  },
  loop: function loop() {
    var looped = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    videoEl.loop = looped;
  },
  /**
   * @param {number} time - the URI to the VAST resource to be loaded - or raw VAST XML if params.vastXmlInput is true
   * @type {() => void}
   */
  seek: function seek(time) {
    if (!this.canInteract) return;
    if (!this.src) return;
    // define whether should continue to play after seek is complete (in seeked hook)
    if (state.playAfterSeek === null) {
      state.playAfterSeek = !!state.playing;
    }
    // pause before actually seeking
    this.pause();
    // currentTime always between 0 and the duration of the video (minus 0.1s to not set to the final frame and stall the video)
    videoEl.currentTime = Math.max(0, Math.min(time, this.duration - 0.1));
  },
  /**
   * @type {() => void}
   */
  registerEventListeners: function registerEventListeners() {
    _registerEventListeners();
  },
  /**
   * @type {() => void}
   */
  pauseEventListeners: function pauseEventListeners() {
    console.info('Pause Event Listeners');
    eventsPaused = true;
  },
  /**
   * @type {() => void}
   */
  resumeEventListeners: function resumeEventListeners() {
    console.info('Resume Event Listeners');
    eventsPaused = false;
  },
  /**
   * @type {() => void}
   */
  deregisterEventListeners: function deregisterEventListeners() {
    _deregisterEventListeners();
  },
  /**
   * @type {() => void}
   */
  skip: function skip(seconds) {
    var _this3 = this;
    if (!this.canInteract) return;
    if (!this.src) return;
    state.skipTime = (state.skipTime || videoEl.currentTime) + seconds;
    easeExecution(function () {
      _this3.seek(state.skipTime);
      state.skipTime = false;
    }, 300);
  },
  /**
   * @type {() => void}
   */
  show: function show() {
    if (!this.canInteract) return;
    if (textureMode === true) {
      videoTexture.show();
    } else {
      videoEl.style.display = 'block';
      videoEl.style.visibility = 'visible';
    }
  },
  /**
   * @type {() => void}
   */
  hide: function hide() {
    if (!this.canInteract) return;
    if (textureMode === true) {
      videoTexture.hide();
    } else {
      videoEl.style.display = 'none';
      videoEl.style.visibility = 'hidden';
    }
  },
  enableAds: function enableAds() {
    var enabled = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    state.adsEnabled = enabled;
  },
  /* Public getters */
  get duration() {
    return videoEl && (isNaN(videoEl.duration) ? Infinity : videoEl.duration);
  },
  get currentTime() {
    return videoEl && videoEl.currentTime;
  },
  get muted() {
    return videoEl && videoEl.muted;
  },
  get looped() {
    return videoEl && videoEl.loop;
  },
  get src() {
    return videoEl && videoEl.getAttribute('src');
  },
  get playing() {
    return state.playing;
  },
  get playingAds() {
    return state.playingAds;
  },
  get canInteract() {
    // todo: perhaps add an extra flag wether we allow interactions (i.e. pauze, mute, etc.) during ad playback
    return state.playingAds === false;
  },
  get top() {
    return videoEl && parseFloat(videoEl.style.top);
  },
  get left() {
    return videoEl && parseFloat(videoEl.style.left);
  },
  get bottom() {
    return videoEl && parseFloat(videoEl.style.top - videoEl.style.height);
  },
  get right() {
    return videoEl && parseFloat(videoEl.style.left - videoEl.style.width);
  },
  get width() {
    return videoEl && parseFloat(videoEl.style.width);
  },
  get height() {
    return videoEl && parseFloat(videoEl.style.height);
  },
  get visible() {
    if (textureMode === true) {
      return videoTexture.isVisible;
    } else {
      return videoEl && videoEl.style.display === 'block';
    }
  },
  get adsEnabled() {
    return state.adsEnabled;
  },
  // prefixed with underscore to indicate 'semi-private'
  // because it's not recommended to interact directly with the video element
  get _videoEl() {
    return videoEl;
  },
  get _consumer() {
    return _consumer;
  }
};
export var MetroPlayer = autoSetupMixin(videoPlayerPlugin, function () {
  precision = AppInstance && AppInstance.stage && AppInstance.stage.getRenderPrecision() || precision;
  videoEl = setupVideoTag();
  textureMode = Settings.get('platform', 'textureMode', false);
  if (textureMode === true) {
    videoEl.setAttribute('crossorigin', 'anonymous');
    videoTexture = setUpVideoTexture();
  }
});
var executeAsPromise = function executeAsPromise(method) {
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var context = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var result;
  if (method && typeof method === 'function') {
    try {
      result = method.apply(context, args);
    } catch (e) {
      result = e;
    }
  } else {
    result = method;
  }

  // if it looks like a duck .. ehm ... promise and talks like a promise, let's assume it's a promise
  if (result !== null && typeof result === 'object' && result.then && typeof result.then === 'function') {
    return result;
  }
  // otherwise make it into a promise
  else {
    return new Promise(function (resolve, reject) {
      if (result instanceof Error) {
        reject(result);
      } else {
        resolve(result);
      }
    });
  }
};
function autoSetupMixin(sourceObject) {
  var setup = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
  var ready = false;
  var doSetup = function doSetup() {
    if (ready === false) {
      setup();
      ready = true;
    }
  };
  return Object.keys(sourceObject).reduce(function (obj, key) {
    if (typeof sourceObject[key] === 'function') {
      obj[key] = function () {
        doSetup();
        return sourceObject[key].apply(sourceObject, arguments);
      };
    } else if (typeof Object.getOwnPropertyDescriptor(sourceObject, key).get === 'function') {
      obj.__defineGetter__(key, function () {
        doSetup();
        return Object.getOwnPropertyDescriptor(sourceObject, key).get.apply(sourceObject);
      });
    } else if (typeof Object.getOwnPropertyDescriptor(sourceObject, key).set === 'function') {
      obj.__defineSetter__(key, function () {
        doSetup();
        return Object.getOwnPropertyDescriptor(sourceObject, key).set.sourceObject[key].apply(sourceObject, arguments);
      });
    } else {
      obj[key] = sourceObject[key];
    }
    return obj;
  }, {});
}
var timeout = null;
var easeExecution = function easeExecution(cb, delay) {
  clearTimeout(timeout);
  timeout = setTimeout(function () {
    cb();
  }, delay);
};