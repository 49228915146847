import { supportedMacros } from './macros';
function track(URLTemplates, macros, options) {
  var URLs = resolveURLTemplates(URLTemplates, macros, options);
  URLs.forEach(function (URL) {
    if (typeof window !== 'undefined' && window !== null) {
      var i = new Image();
      i.src = URL;
    }
  });
}

/**
 * Replace the provided URLTemplates with the given values
 *
 * @param {Array} URLTemplates - An array of tracking url templates.
 * @param {Object} [macros={}] - An optional Object of parameters to be used in the tracking calls.
 * @param {Object} [options={}] - An optional Object of options to be used in the tracking calls.
 */
function resolveURLTemplates(URLTemplates) {
  var macros = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var resolvedURLs = [];
  var URLArray = extractURLsFromTemplates(URLTemplates);

  // Set default value for invalid ERRORCODE
  if (macros['ERRORCODE'] && !options.isCustomCode && !/^[0-9]{3}$/.test(macros['ERRORCODE'])) {
    macros['ERRORCODE'] = 900;
  }

  // Calc random/time based macros
  macros['CACHEBUSTING'] = leftpad(Math.round(Math.random() * 1.0e8).toString());
  macros['TIMESTAMP'] = new Date().toISOString();

  // RANDOM/random is not defined in VAST 3/4 as a valid macro tho it's used by some adServer (Auditude)
  macros['RANDOM'] = macros['random'] = macros['CACHEBUSTING'];
  for (var macro in macros) {
    macros[macro] = encodeURIComponentRFC3986(macros[macro]);
  }
  for (var URLTemplateKey in URLArray) {
    var resolveURL = URLArray[URLTemplateKey];
    if (typeof resolveURL !== 'string') {
      continue;
    }
    resolvedURLs.push(replaceUrlMacros(resolveURL, macros));
  }
  return resolvedURLs;
}

/**
 * Replace the macros tracking url with their value.
 * If no value is provided for a supported macro and it exists in the url,
 * it will be replaced by -1 as described by the VAST 4.1 iab specifications
 *
 * @param {String} url - Tracking url.
 * @param {Object} macros - Object of macros to be replaced in the tracking calls
 */
function replaceUrlMacros(url, macros) {
  url = replaceMacrosValues(url, macros);
  // match any macros from the url that was not replaced
  var remainingMacros = url.match(/[^[\]]+(?=])/g);
  if (!remainingMacros) {
    return url;
  }
  var supportedRemainingMacros = remainingMacros.filter(function (macro) {
    return supportedMacros.indexOf(macro) > -1;
  });
  if (supportedRemainingMacros.length === 0) {
    return url;
  }
  supportedRemainingMacros = supportedRemainingMacros.reduce(function (accumulator, macro) {
    accumulator[macro] = -1;
    return accumulator;
  }, {});
  return replaceMacrosValues(url, supportedRemainingMacros);
}

/**
 * Replace the macros tracking url with their value.
 *
 * @param {String} url - Tracking url.
 * @param {Object} macros - Object of macros to be replaced in the tracking calls
 */
function replaceMacrosValues(url, macros) {
  var replacedMacrosUrl = url;
  for (var key in macros) {
    var value = macros[key];
    // this will match [${key}] and %%${key}%% and replace it
    replacedMacrosUrl = replacedMacrosUrl.replace(new RegExp("(?:\\[|%%)(".concat(key, ")(?:\\]|%%)"), 'g'), value);
  }
  return replacedMacrosUrl;
}

/**
 * Extract the url/s from the URLTemplates.
 *   If the URLTemplates is an array of urls
 *   If the URLTemplates object has a url property
 *   If the URLTemplates is a single string
 *
 * @param {Array|String} URLTemplates - An array|string of url templates.
 */
function extractURLsFromTemplates(URLTemplates) {
  if (Array.isArray(URLTemplates)) {
    return URLTemplates.map(function (URLTemplate) {
      return URLTemplate && URLTemplate.hasOwnProperty('url') ? URLTemplate.url : URLTemplate;
    });
  }
  return URLTemplates;
}

/**
 * Returns a boolean after checking if the object exists in the array.
 *   true - if the object exists, false otherwise
 *
 * @param {Object} obj - The object who existence is to be checked.
 * @param {Array} list - List of objects.
 */
function containsTemplateObject(obj, list) {
  for (var i = 0; i < list.length; i++) {
    if (isTemplateObjectEqual(list[i], obj)) {
      return true;
    }
  }
  return false;
}

/**
 * Returns a boolean after comparing two Template objects.
 *   true - if the objects are equivalent, false otherwise
 *
 * @param {Object} obj1
 * @param {Object} obj2
 */
function isTemplateObjectEqual(obj1, obj2) {
  if (obj1 && obj2) {
    var obj1Properties = Object.getOwnPropertyNames(obj1);
    var obj2Properties = Object.getOwnPropertyNames(obj2);

    // If number of properties is different, objects are not equivalent
    if (obj1Properties.length !== obj2Properties.length) {
      return false;
    }
    if (obj1.id !== obj2.id || obj1.url !== obj2.url) {
      return false;
    }
    return true;
  }
  return false;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
function encodeURIComponentRFC3986(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%".concat(c.charCodeAt(0).toString(16));
  });
}
function leftpad(input) {
  var len = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 8;
  var str = String(input);
  if (str.length < len) {
    return range(0, len - str.length, false).map(function () {
      return '0';
    }).join('') + str;
  }
  return str;
}
function range(left, right, inclusive) {
  var result = [];
  var ascending = left < right;
  var end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (var i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    result.push(i);
  }
  return result;
}
function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
function flatten(arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}

/**
 * Joins two arrays of objects without duplicates
 *
 * @param {Array} arr1
 * @param {Array} arr2
 *
 * @return {Array}
 */
function joinArrayOfUniqueTemplateObjs() {
  var arr1 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var arr2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var firstArr = Array.isArray(arr1) ? arr1 : [];
  var secondArr = Array.isArray(arr2) ? arr2 : [];
  var arr = firstArr.concat(secondArr);
  return arr.reduce(function (res, val) {
    if (!containsTemplateObject(val, res)) {
      res.push(val);
    }
    return res;
  }, []);
}
export var util = {
  track: track,
  resolveURLTemplates: resolveURLTemplates,
  extractURLsFromTemplates: extractURLsFromTemplates,
  containsTemplateObject: containsTemplateObject,
  isTemplateObjectEqual: isTemplateObjectEqual,
  encodeURIComponentRFC3986: encodeURIComponentRFC3986,
  replaceUrlMacros: replaceUrlMacros,
  leftpad: leftpad,
  range: range,
  isNumeric: isNumeric,
  flatten: flatten,
  joinArrayOfUniqueTemplateObjs: joinArrayOfUniqueTemplateObjs
};