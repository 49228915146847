function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Registry, Router, VideoPlayer } from '@lightningjs/sdk';
import isDate from 'lodash-es/isDate';
import isFun from 'lodash-es/isFunction';
import isNum from 'lodash-es/isNumber';
import isStr from 'lodash-es/isString';
var debug = new Debugger('utils');
export var isNumber = isNum;
export var isFunction = isFun;
export var isString = isStr;
export function isValidDate(last) {
  return isDate(last) && isFinite(last.getTime());
}
export function parseIdFromPath(path) {
  path = path.trim();
  path = path.replace(/^\//, '').replace(/\/$/, '');
  if (path.length === 0) return null;
  var matches = path.match(/^(details|player)\/(movie|tv)\/([^/]*)/);
  return matches && matches[3] ? matches[3] : null;
}
export function isBoolean(x) {
  return x === true || x === false;
}
export function safeHash() {
  try {
    return document.location.hash.replace(/^#/, '');
  } catch (e) {
    var hash = Router.getActiveHash();
    return hash ? hash.replace(/^#/, '') : 'unknown';
  }
}
export function getProcessVar(key) {
  try {
    if (typeof process !== undefined && typeof process.env !== undefined) {
      var _ref;
      var value = process.env[key];
      return (_ref = value) !== null && _ref !== void 0 ? _ref : null;
    } else {
      console.warn('process.env not set.');
      return null;
    }
  } catch (e) {
    console.warn('Error getting process const %s', e.message);
    return null;
  }
}
import { AdeBoxArtButton, BoxArtHeight } from '../components';
import { NotFoundMessageError } from './coded-error';
import { Debugger } from './debugger';
import { expandDimensions, getCoordinateDimensions, getCoordindatesFromPatch, isComponentDimensions } from './lightning-tools';
export function boxListItems(content) {
  var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return content.map(function (c) {
    return _objectSpread({
      type: AdeBoxArtButton,
      h: isNumber(opts['h']) ? opts['h'] : 218,
      content: c,
      imageSrc: c.images.box.getForWidth((isNumber(opts['h']) ? opts['h'] : BoxArtHeight) * 44 / 66)
    }, opts);
  });
}
export function sortByKey(key, a, b) {
  var aValue = a[key];
  var bValue = b[key];
  var aNumber = isNumber(aValue) ? aValue : Infinity;
  var bNumber = isNumber(bValue) ? bValue : Infinity;
  return aNumber - bNumber;
}
export function getFirstSeason(item) {
  if (!Array.isArray(item.seasons)) return null;
  var sorted = item.seasons.filter(function (_ref2) {
    var number = _ref2.number;
    return number !== 0;
  }).sort(function (a, b) {
    return sortByKey('number', a, b);
  });
  return sorted[0];
}
export function getFirstEpisode(item) {
  if (!Array.isArray(item.episodes)) return null;
  var sorted = item.episodes.sort(function (a, b) {
    return sortByKey('episodeNumber', a, b);
  });
  return sorted[0];
}
export function hashCode(s) {
  return s.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0).toString();
}
export function getExtension(url) {
  if (isString(url)) {
    var items = url.split(/[#?]/)[0].split('.');
    return items.length > 0 ? items.pop().trim().toLowerCase() : null;
  } else {
    console.warn('Did not get string to pull extension from', url);
    return null;
  }
}
export function isGoodArray(x) {
  return Array.isArray(x) && x.length > 0;
}
export function getMovieMedia(item) {
  if (item.type !== 'movie') throw new Error('Content Item is not a movie');
  var media = item.media;
  if (isGoodArray(media) && media.length > 0) {
    var movie = media.find(function (_ref3) {
      var type = _ref3.type;
      return type === 'movie';
    });
    if (movie) return movie;
    var trailer = media.find(function (_ref4) {
      var type = _ref4.type,
        extra_type = _ref4.extra_type;
      return type === 'extra' && extra_type === 'trailer';
    });
    if (trailer) return trailer;
  }
  throw new NotFoundMessageError("No media for ".concat(item.title), 'Unable to locate playable media for this content');
}
export function isMessagedError(error) {
  return isString(error.code) && isString(error.title);
}
export function spacePause(e) {
  if (e.code === 'Space' && VideoPlayer.src) {
    if (VideoPlayer.playing) VideoPlayer.pause();else VideoPlayer.play();
  }
}
export function objectEntries(obj) {
  return Object.entries(obj);
}
export function hasGoodEpisodes(episodes) {
  if (isGoodArray(episodes)) {
    var now = new Date();
    var hasGood = episodes.reduce(function (acc, episode) {
      var goodDate = isDate(episode.air_date) && episode.air_date < now;
      return acc || goodDate;
    }, false);
    return hasGood;
  } else {
    return false;
  }
}
export function goodEpisodes(episodes) {
  if (isGoodArray(episodes)) {
    var now = new Date();
    return episodes.filter(function (episode) {
      return isDate(episode.air_date) && episode.air_date < now;
    });
  } else {
    return [];
  }
}
export var sleep = function sleep(ms) {
  return new Promise(function (resolve) {
    Registry.setTimeout(resolve, ms);
  });
};
export function delay(func, wait) {
  for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    args[_key - 2] = arguments[_key];
  }
  return Registry.setTimeout(function () {
    func.call.apply(func, [undefined].concat(args));
  }, wait);
}
export function defer(func) {
  for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    args[_key2 - 1] = arguments[_key2];
  }
  return delay.apply(void 0, [func, 0].concat(args));
}
export function getSafeHash() {
  try {
    if (document !== undefined && document.location !== undefined) {
      var cleanHash = document.location.hash.replace(/^[#/$]*/, '').trim();
      return cleanHash.length > 0 ? cleanHash : null;
    }
  } catch (error) {
    console.warn('Safe hash error');
  }
  return null;
}
export function simpleUUID() {
  var d = new Date().getTime(); //Timestamp
  var d2 = typeof performance !== 'undefined' && performance.now && performance.now() * 1000 || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
  });
}
export function pathJoin(parts, sep) {
  parts = Array.isArray(parts) ? parts : [parts];
  var separator = sep || '/';
  var replace = new RegExp(separator + '{1,}', 'g');
  return parts.join(separator).replace(replace, separator);
}
export function splitAndLowerPath(s) {
  var delimiter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
  return s.split(delimiter).map(function (s) {
    return s.toLowerCase();
  });
}
export function getSafeDocument() {
  try {
    if (typeof document !== undefined && isFun(document.addEventListener)) {
      return document;
    } else {
      return null;
    }
  } catch (e) {
    console.warn('Error getting save document ' + e.message, {
      error: e
    });
    return null;
  }
}
var withSymbol = typeof Symbol !== 'undefined';
function isScalar(value) {
  var type = typeof value;
  if (type === 'string') return true;
  if (type === 'number') return true;
  if (type === 'boolean') return true;
  if (withSymbol === true && type === 'symbol') return true;
  if (value == null) return true;
  if (withSymbol === true && value instanceof Symbol) return true;
  if (value instanceof String) return true;
  if (value instanceof Number) return true;
  if (value instanceof Boolean) return true;
  return false;
}
export function isScrollTransition(x) {
  return x != null && !isScalar(x) && x.isTransition === true;
}
function getFuzz(number) {
  if (!number) return [null, null];
  return Array.isArray(number) ? number : [number, number];
}
function updateValue(value, fuzz) {
  if (fuzz == null) return value;else {
    return fuzz > 0 && fuzz <= 1 ? value * fuzz : value + fuzz;
  }
}
function getBoundsTest(target) {
  target = isComponentDimensions(target) ? getCoordindatesFromPatch(target) : target;
  return {
    left: target.x,
    right: target.x + target.width,
    top: target.y,
    bottom: target.y + target.height
  };
}
export function testWithinContainer(container, test, direction) {
  if (direction === 'horizontal') {
    return componentWithinContainerHorizontally(container, test);
  } else if (direction === 'vertical') {
    return componentWithinContainerVertically(container, test);
  } else {
    return isFullyWithinContainer(getCoordinateDimensions(container), getCoordinateDimensions(test));
  }
}
export function componentWithinContainerVertically(bucket, target) {
  return isWithinContainerVertically(getCoordinateDimensions(bucket), getCoordinateDimensions(target));
}
export function componentWithinContainerHorizontally(bucket, target) {
  return isWithinContainerHorizontally(getCoordinateDimensions(bucket), getCoordinateDimensions(target));
}
export function isWithinContainerVertically(bucket, target) {
  var truth = getBoundsTest(bucket);
  var test = getBoundsTest(target);
  return test.top >= truth.top && test.bottom <= truth.bottom;
}
export function isWithinContainerHorizontally(bucket, target) {
  var truth = getBoundsTest(bucket);
  var test = getBoundsTest(target);
  return test.left >= truth.left && test.right <= truth.right;
}
export function isFullyWithinContainer(bucket, target) {
  return isWithinContainerVertically(bucket, target) && isWithinContainerHorizontally(bucket, target);
}
export function stamper() {
  var txt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Stamp';
  return function () {
    var deb = new Debugger(txt);
    deb.info(new Date().getTime().toString().slice(-5));
  };
}
export function getParentWrapper(component) {
  var parent = component.parent;
  if (!parent) return null;
  return parent && parent.ref === 'Wrapper' ? parent.parent : parent;
}
export function isWithinParentBounds(component) {
  var fuzz = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var parent = getParentWrapper(component);
  if (!parent) return null;
  var myCoords = getCoordinateDimensions(component, true);
  var parentCoords = getCoordinateDimensions(parent, true);
  if (fuzz !== 0) {
    myCoords = expandDimensions(myCoords, fuzz, true);
  }
  return isFullyWithinContainer(parentCoords, myCoords);
}