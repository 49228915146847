import { isGoodString } from '../lightning-tools';
export function isSearchIntent(x) {
  return x != null && x.action === 'search';
}
export function isProgramEntity(x) {
  var _ref = x,
    _ref$entityType = _ref.entityType,
    entityType = _ref$entityType === void 0 ? null : _ref$entityType;
  return isGoodString(entityType) && entityType === 'program';
}
export function isTVEpisodeEntity(data) {
  return isProgramEntity(data) && data.programType === 'episode';
}
export function isTVSeasonEntity(data) {
  return isProgramEntity(data) && data.programType === 'season';
}
export function isTVSeriesEntity(data) {
  return isProgramEntity(data) && data.programType === 'series';
}
export function isMovieEntity(data) {
  return isProgramEntity(data) && data.programType === 'movie';
}